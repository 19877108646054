<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { ref, reactive } from 'vue'
import { typeList, getPage, getHotPage } from '@/api'

let router = useRouter()
const ci: any = ref(0)
const classList: any = ref([])
const infoList: any = ref([])
const infoHotList: any = ref([])
const total: any = ref(0)
const searchForm = reactive({
  page: 1,
  limit: 10,
  newspaperTypeId: 0,
  source: 10,
})
typeList({ source: 10 }).then((res) => {
  if (res.code === 200) {
    classList.value = res.data
  }
})
const getHotList = () => {
  getHotPage({ source: 10, count: 5 }).then((res) => {
    if (res.code === 200) {
      infoHotList.value = res.data
    }
  })
}
const getList = () => {
  if (classList.value.length === 0) {
    typeList({ source: 10 }).then((res) => {
      if (res.code === 200) {
        classList.value = res.data
        if (classList.value.length !== 0) {
          ci.value = 0
          searchForm.newspaperTypeId = classList.value[ci.value].id
          getPage(searchForm).then((res) => {
            if (res.code === 200) {
              infoList.value = res.data.records
              total.value = parseInt(res.data.total, 10)
            }
          })
        }
      }
    })
  } else {
    searchForm.newspaperTypeId = classList.value[ci.value].id
    getPage(searchForm).then((res) => {
      if (res.code === 200) {
        infoList.value = res.data.records
        total.value = parseInt(res.data.total, 10)
      }
    })
  }
}
getHotList()
getList()
const changeClass = (index: any) => {
  ci.value = index
  getList()
}
const jumpContent = (id: any) => {
  router.push({
    name: 'InfoContent',
    query: { id },
  })
}
</script>
<template>
  <div class="background">
    <div class="text1">加速资讯</div>
    <div class="classlist">
      <div
        v-for="item, index in classList" :key="index" class="item" :class="{ select: index == ci }"
        @click="changeClass(index)">
        {{ item.name }}
      </div>
    </div>
    <div class="box">
      <div v-for="item, index in infoList" :key="index" class="item" @click="jumpContent(item.id)">
        <img class="cover" :src="item.coverImage">
        <div class="main">
          <div class="title">{{ item.title }}</div>
          <div class="info">{{ item.abstractInfo }}</div>
          <div class="down">
            <div class="view">
              <img class="see" src="@/assets/images/icon_browse.png">
              {{ item.viewCount }}次
            </div>
            <div class="time">{{ item.createTime.substring(0, 10) }}发布</div>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      v-show="total > 0" v-model:page="searchForm.page" v-model:limit="searchForm.limit" :total="total"
      @pagination="getList" />
  </div>
</template>
<style lang="less" scoped>
.background {
  width: 100%;
  background-color: #1F2322;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 0 40px 0;

  .text1 {
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .classlist {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding: 0 16px;

    .item {
      cursor: pointer;
      width: 96px;
      margin-right: 12px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 32px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 32px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.select {
        background: #CCFCF8;
        color: #00665D;
      }
    }
  }

  .box {
    margin-top: 32px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 166px);
    grid-template-rows: repeat(1, 188px);
    grid-row-gap: 15px;
    justify-content: space-evenly;

    .item {
      cursor: pointer;
      width: 166px;
      height: 188px;
      background: #FFF;
      border-radius: 10px;

      .cover {
        width: 166px;
        height: 92px;
        border-radius: 10px 10px 0 0;
      }

      .main {
        padding: 6px 8px 10px 8px;

        .title,
        .info {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

        .title {
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
          -webkit-line-clamp: 1;
        }

        .info {
          height: 34px;
          font-size: 12px;
          color: #666;
          line-height: 17px;
          -webkit-line-clamp: 2;
          margin: 4px 0 8px 0;
        }

        .down {
          display: flex;
          justify-content: space-between;
          color: #CCC;

          .view {
            display: flex;
            align-items: center;
            font-size: 10px;
            line-height: 14px;

            .see {
              width: 12px;
              height: 12px;
              margin-right: 4px
            }
          }

          .time {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }
  }
}
</style>
