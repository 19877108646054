<!-- eslint-disable vue/no-v-html -->
<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import { getDetailById } from '@/api'

let route = useRoute()
const info: any = ref({})
onMounted(() => {
  getDetailById({ id: route.query.id }).then((res) => {
    if (res.code === 200) {
      info.value = res.data
    }
  })
})
</script>
<template>
  <div class="background">
    <div class="box">
      <div class="text1">{{ info.title }}</div>
      <div class="text2">
        <div class="time">{{ info.createTime?.substring(0, 10) }}发布</div>
        <div class="view">
          <img class="see" src="@/assets/images/icon_browse.png">
          {{ info.viewCount }}次
        </div>
      </div>
      <div v-html="info.content" />
    </div>
  </div>
</template>
<style lang="less" scoped>
.background {
    width: 100%;
    background-color: #1F2322;
    color: #333333;
    padding: 105px 16px 39px 16px;

    .box {
        width: 100%;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 60px;

        .text1 {
            font-size: 22px;
            line-height: 30px;
            font-weight: bold;
            margin-bottom: 32px;
        }

        .text2 {
            font-size: 12px;
            color: #CCCCCC;
            line-height: 17px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 12px;border-bottom: 1px solid #ECEEF0;
            .view{
            display: flex;
            align-items: center;
                .see{
              width: 16px;
              height: 16px;
              margin-right: 8px
            }
            }
        }
    }
}
</style>
