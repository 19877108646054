import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// import { QuillEditor } from '@vueup/vue-quill'
import App from './App.vue'
import '@/styles/reset.less'
import router from './router'
import Pagination from '@/components/Pagination.vue'
// import UploadImage from '@/components/UploadImage.vue'
// import OneEnterPrise from '@/components/OneEnterPrise.vue'
// import '@vueup/vue-quill/dist/vue-quill.snow.css'

const app = createApp(App)
app.use(router).use(createPinia()).use(ElementPlus, {
  locale: zhCn,
}).mount('#app')
app.component('Pagination', Pagination)
// .component('QuillEditor', QuillEditor).component('UploadImage', UploadImage).component('OneEnterPrise', OneEnterPrise)
