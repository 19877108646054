<template>
  <div class="back">
    <div>
      <img style="width: 87px;height: 18px;" src="@/assets/images/shandian_logo.png" @click="router.push('/')">
      <div>
        <div v-if="vuex.$state.isLogin">
          <div />
        </div>
        <div v-else>
          <div />
        </div>
        <el-menu router default-active="/" :ellipsis="false" mode="horizontal" menu-trigger="click">
          <el-sub-menu index="2-4">
            <template #title>
              <div class="menu" />
            </template>
            <el-menu-item index="/about">软件下载</el-menu-item>
            <el-menu-item index="/vip">会员充值</el-menu-item>
            <el-menu-item index="/info">加速资讯</el-menu-item>
            <el-menu-item index="/help">常见问题</el-menu-item>
            <el-menu-item index="/cdk">CDK兑换</el-menu-item>
            <el-menu-item index="/login">{{ vuex.$state.isLogin ? "用户信息" : "注册/登录" }}</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </div>
    </div>
  </div>
  <router-view />
  <div class="downloadnow">
    <div class="nowuse">即刻使用，安全、极速、稳定畅游全球</div>
    <div class="todown" @click="todown">立即下载</div>
  </div>
  <div class="footer">
    <div>
      <el-button link @click="jumpUser">《用户协议》</el-button>与<el-button style="margin: 0;" link @click="jumpPirv">
        《隐私协议》
      </el-button>
    </div>
    <div>软件名称：雷电加速器</div>
    <div><el-button link @click="jumpBeian">浙ICP备2021003980号-1</el-button></div>
    <div>开发者名称：杭州棉致科技有限公司</div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'

let router = useRouter()

const vuex = useStore()
vuex.getOptionList()
onMounted(() => {
  if (localStorage.getItem('lsspeedtoken')) {
    vuex.$state.isLogin = true
    vuex.$state.name = localStorage.getItem('lsspeedname') || ''
  }
})
const jumpBeian = () => {
  window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
}
const jumpUser = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/user')
}
const jumpPirv = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/privacy')
}
const todown = () => {
  const main = document.getElementById('app')
  if (main) { main.scrollTop = 0 }
  // document.getElementById('app').scrollTop = 0
  router.push('/')
}
</script>
<style lang="less" scoped>
.back {
  width: 100%;

  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 24px;
    backdrop-filter: blur(5px);
  }
}

.icon {
  width: 28px;
  height: 28px;
  margin-right: 16px;
}

.login {
  padding: 9px 31px;
}

.menu {
  width: 1em;
  height: 1em;
}

.downloadnow {
  width: 100%;
  height: 130px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #121818;

  >.nowuse {
    font-size: 16px;
    color: #00DAC6;
    line-height: 22px;
    font-weight: bold;
    margin: 32px 0 16px 0;
    z-index: 1;
  }

  >.todown {
    font-size: 14px;
    color: #FFFFFF;
    padding: 6px 26px;
    line-height: 20px;
    background: linear-gradient(90deg, #00DDD5 0%, #00BDCB 100%);
    border-radius: 18px;
  }
}

.footer {
  width: 100%;
  height: 130px;
  text-align: center;
  color: #fff;
  line-height: 17px;
  background: #121818;

  >div {
    font-size: 12px;
    margin-bottom: 10px;
    --el-font-size-base: 12px;
  }
}
</style>
<style lang="less">
.el-menu-item {
  font-size: 16px
}

.el-menu--horizontal,
.el-menu--horizontal>.el-menu-item,
.el-menu--horizontal>.el-menu-item.is-active {
  border: none;
}

.el-popper.is-pure {
  background: #121818;
  border: none;
}

.el-menu {
  background-color: transparent;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-sub-menu__title {
  background: #121818;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  height: 52px;
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);

  &:last-child {
    border: none;
  }
}

.el-menu--popup {
  min-width: 188px;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-sub-menu.is-active>.el-sub-menu__title {
  background-color: transparent;
  color: #00DAC6;
}

.el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title,
.el-menu--horizontal>.el-sub-menu:hover .el-sub-menu__title,
.el-menu--horizontal>.el-sub-menu .el-sub-menu__title {
  border: none;
  color: #fff;
}

.gird22 {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: 52px 52px;
  grid-gap: 20px;
  margin: 20px;

  >.el-button {
    width: 200px;
    padding: 15px 35px;
    font-size: 16px;
    line-height: 22px;
    height: auto;
    margin: 0;
  }
}
#chatBtn{
  img{
    width: 25px;
    height: 25px;
    display: inline;
  }
}
</style>
