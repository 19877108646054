<script lang="ts" setup>
import { ref } from 'vue'
// import { ElMessage } from 'element-plus'
import { useStore } from '@/store/index'

const vuex = useStore()
const appraiseList = ref([{
  text: 'TED加速非常有效，演讲视频加载很快！收费正常价格！网络一点都不卡！用了一段时间无法加速，然后邮件反馈了一下，客服那边很快就反馈让我更新，更新后就好了，客服还在一直邮件跟进后续使用情况。',
  name: '爱学习的小熊',
  time: '2023-06-28  21:32:33',
}, {
  text: '刚用雷电加速器的时候还只有APP，因为平时和海外的部门要开视频会议APP比较麻烦，所以反馈希望出PC端，没想到很迅速，过了一个月发现出PC端了哈哈哈，很棒，以后视频会议很方便了。',
  name: '每天都很忙',
  time: '2023-03-18  21:32:33',
}, {
  text: '海外留学被地区限制搞的欲哭无泪，现在终于能看番听歌啦！感谢雷电加速器！不限速太良心！刚出的庆余年也看上了，我已经推荐给我身边的留学党了，哈哈。',
  name: 'Derek',
  time: '2023-06-12  21:32:33',
}, {
  text: '在海外工作访问公司的国内服务器，必须要用这款加速器。另外平时访问富途牛牛、通达信、大智慧等软件都非常快速稳定，可以说是办公必备了。',
  name: 'Ashley',
  time: '2023-01-15  21:32:33',
}, {
  text: '我平时比较喜欢玩国际服的一些游戏，延迟太高了没法玩，用过一些加速器也很卡，朋友给我推荐了雷电加速器，用起来延迟降低了很多，基本不会出现卡顿，打起团来也非常的丝滑。',
  name: 'Eric',
  time: '2023-09-07  21:32:33',
}, {
  text: '亲测效果很好，一开始买了个不知名加速器，也不确定好不好用，不过用了一段时间觉得蛮好用，特地来打个好评，希望能帮上各位，主要是安装在pad上给孩子海外平台学英语，很方便。',
  name: 'Sunny',
  time: '2023-03-02  21:32:33',
}])
const download = (filename:any, url:any) => {
  let a = document.createElement('a')
  a.style.display = 'none' // 创建一个隐藏的a标签
  a.download = filename
  a.href = url
  document.body.appendChild(a)
  a.click() // 触发a标签的click事件
  document.body.removeChild(a)
}
const winDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 3)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const andDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 1)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const iosDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 2)
  window.open(vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const macDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 4)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
</script>
<template>
  <div class="box1">
    <div class="text1">畅连无碍</div>
    <div class="text2">延迟更低 网速更快</div>
    <div class="tag">
      <div class="box" @click="iosDownload">
        <img class="img" src="@/assets/images/apple_nor.png">
        APP Store
      </div>
      <div class="box" @click="andDownload">
        <img class="img" src="@/assets/images/android_nor.png">
        Android版下载
      </div>
      <div class="box" @click="winDownload">
        <img class="img" src="@/assets/images/win_nor.png">
        Windows下载
      </div>
      <div class="box" @click="macDownload">
        <img class="img" src="@/assets/images/macos_nor.png">
        MacOS
      </div>
    </div>
  </div>
  <div class="box2">
    <div class="text1">优质加速能力 服务于用户</div>
    <div class="text2">为每一位使用雷电加速器的用户保驾护航</div>
    <div class="tag">
      <div class="box">
        <div class="title">全球网络稳定加速是<span style="color: #00665D;">保障</span></div>
        <div class="title">智能加速降低延迟是<span style="color: #00665D;">技术</span></div>
        <div class="title">自研加密放心冲浪是<span style="color: #00665D;">安全</span></div>
      </div>
      <img class="img" src="@/assets/images/image_guarantee.png">
    </div>
  </div>
  <div class="box3">
    <div class="text1">跨境解决方案 用雷电 就够了</div>
    <div class="text2">可以满足你对网络的各种需求</div>
    <div class="text2">支持视频、游戏、直播和办公等加速</div>
    <div class="tag">
      <div class="box">
        <img style="width: 49px;height: 50px;margin-right: 19px;" src="@/assets/images/icon_shop.png">
        <div class="text">
          <div class="title">跨境电商</div>
          <div class="content">助力跨境电商，网络加速如虎添翼！无论你身在何地，我们都能为你提供快速、稳定的网络连接，让你在跨境电商的海洋中畅游无阻。</div>
        </div>
      </div>
      <div class="box">
        <img style="width: 39px;height: 41px;margin-right: 24px;" src="@/assets/images/icon_game.png">
        <div class="text">
          <div class="title">游戏加速</div>
          <div class="content">带你畅玩国际服游戏！突破网络限制，尽享超低延迟，告别卡顿和掉线。</div>
        </div>
      </div>
      <div class="box">
        <img style="width: 47px;height: 46px;margin-right: 22px;" src="@/assets/images/icon_education.png">
        <div class="text">
          <div class="title">教育加速</div>
          <div class="content">助力国际教育，连接知识无界。无论你身在何处，都能畅快访问全球优质教育资源，与世界各地的学生和老师实时互动。</div>
        </div>
      </div>
      <div class="box">
        <img style="width: 59px;height: 62px;margin-right: 16px;" src="@/assets/images/icon_office.png">
        <div class="text">
          <div class="title">办公加速</div>
          <div class="content">国际办公的得力助手！快速稳定的网络连接，让你与全球团队紧密协作，文件传输、视频会议畅通无阻。开启你的高效办公新时刻。</div>
        </div>
      </div>
    </div>
  </div>
  <div class="box4">
    <div class="text1">回国解决方案 毫秒必争</div>
    <div class="text2">雷电回国 一键轻松助力海外华人和留学游子无障碍畅享国内资源</div>
    <div class="tag">
      <div class="box">
        <div>
          <div class="title">看视频听音乐</div>
          <div class="text">解决因地域限制导致的音乐视频APP无法播放的问题。畅玩网易云、QQ音乐，畅享爱奇艺、优酷、bilibili、腾讯视频，秒开1080P。</div>
        </div>
        <img class="img" src="@/assets/images/icon_video.png">
      </div>
      <div class="box">
        <div>
          <div class="title">购物达人</div>
          <div class="text">轻松访问国内购物软件，可以快速定位国内商品，满足用户随时随地浏览或购买国内商品。</div>
        </div>
        <img class="img" src="@/assets/images/icon_shop(1).png">
      </div>
      <div class="box">
        <div>
          <div class="title">直播语音</div>
          <div class="text">加速斗鱼、虎牙、YY语音、抖音直播、淘宝直播等。高速节点，保障画面稳定流畅不卡顿。</div>
        </div>
        <img class="img" src="@/assets/images/icon_live.png">
      </div>
      <div class="box">
        <div>
          <div class="title">办公学习</div>
          <div class="text">加速网页，保障你能正常访问国内教育网站和办公软件进行线上办公，一键加速同花顺、大智慧等。</div>
        </div>
        <img class="img" src="@/assets/images/icon_study.png">
      </div>
    </div>
  </div>
  <div class="box5">
    <div class="text1">上线半年已累计用户50W+</div>
    <div class="text2">看看大家是如何评价雷电加速器</div>
    <div class="tag">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item, index in appraiseList" :key="index">
          <div class="box">
            <div class="text">{{ item.text }}</div>
            <div class="people">
              <img v-if="index == 0" class="img" src="@/assets/images/head1.jpg">
              <img v-if="index == 1" class="img" src="@/assets/images/head2.jpg">
              <img v-if="index == 2" class="img" src="@/assets/images/head3.jpg">
              <img v-if="index == 3" class="img" src="@/assets/images/head4.png">
              <img v-if="index == 4" class="img" src="@/assets/images/head5.png">
              <img v-if="index == 5" class="img" src="@/assets/images/head6.jpg">
              <div class="right">
                <div class="name">{{ item.name }}</div>
                <div class="time">{{ item.time }}</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<style lang="less" scoped>
.box1 {
  width: 100%;
  padding-top: 128px;

  .text1 {
    text-align: center;
    font-size: 40px;
    color: #FFF;
    line-height: 56px;
    font-weight: bold;
  }

  .text2 {
    margin: 20px 0;
    font-size: 30px;
    color: #FFF;
    line-height: 42px;
    text-align: center;
  }

  .tag {
    width: 100%;
    margin: 48px 0 218px 0;
    display: grid;
    text-align: center;
    grid-template-columns: 158px 158px;
    grid-template-rows: 60px 60px;
    grid-gap: 16px 19px;
    justify-content: center;

    .img {
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }

    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #FFF;
      line-height: 20px;
      border-radius: 4px;
      border: 1px solid #fff;

      &.hover {
        background: #FFFFFF;
        color: #00665D;
      }
    }
  }
}

.box2 {
  background: #fff;
  width: 100%;
  color: #333333;
  padding: 58px 0 75px 0;

  .text1 {
    width: 100%;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 16px 0;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }

  .tag {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 66px;

    .box {

      .title {
        margin-top: 38px;
        font-size: 14px;
        line-height: 20px;
        color: #666;

        &:first-child {
          margin-top: 0;
          color: #333;
        }
      }
    }

    .img {
      width: 160px;
      height: 128px;
    }
  }
}

.box3 {
  width: 100%;
  color: #fff;
  padding: 60px 0 49px 0;
  background-color: #1F2322;

  .text1 {
    width: 100%;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
  }

  .text2 {
    width: 100%;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }

  .tag {
    width: 100%;
    margin: 24px 0 49px 0;

    .box {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {

        .title {
          margin-bottom: 4px;
          font-size: 18px;
          line-height: 25px;
          font-weight: bold;
        }

        .content {
          width: 268px;
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
}

.box4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #333;
  padding: 60px 0 48px 0;
  background-color: #fff;

  .text1 {
    width: 100%;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 12px 0 24px 0;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }

  .tag {
    width: 100%;

    .box {
      height: 120px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .title {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 25px;
        font-weight: bold;
      }

      .text {
        width: 256px;
        font-size: 12px;
        color: #808080;
        line-height: 17px;
      }

      .img {
        width: 60px;
        height: 60px;
      }

      &:last-child {
        color: #fff;
        background: #444D4C;

        .text {
          color: #fff;
        }
      }
    }
  }
}

.box5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;
  padding: 60px 0 65px 0;
  background-color: #1F2322;

  .text1 {
    width: 100%;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 12px 0 32px 0;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }

  .tag {
    width: 100%;

    .box {
      width: 343px;
      margin: 0 auto;
      padding: 28px;
      background: rgba(255, 255, 255, 0.04);
      border-radius: 8px;

      .text {
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 36px;
      }

      .people {
        display: flex;
        align-items: center;

        .img {
          width: 48px;
          height: 48px;
          margin-right: 12px;
        }

        .right {
          .name {
            font-size: 18px;
            line-height: 25px;
          }

          .time {
            font-size: 12px;
            color: #CCCCCC;
            line-height: 17px;
          }
        }
      }
    }
  }
}
</style>
