import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import { getRouterList } from '@/utils/routerGlob'

// const fileList = getRouterList()

declare module 'vue-router'{
  interface _RouteRecordBase {
    hidden?: boolean | string | number,
  }
}
const Layout = () =>
  import('@/Layout/index.vue')
const Home = () =>
  import('@/views/Home/index.vue')
const About = () =>
  import('@/views/About/index.vue')
const CDK = () =>
  import('@/views/CDK/index.vue')
const Help = () =>
  import('@/views/Help/index.vue')
const Info = () =>
  import('@/views/Info/index.vue')
const InfoContent = () =>
  import('@/views/Info/InfoContent.vue')
const VIP = () =>
  import('@/views/VIP/index.vue')
const Login = () =>
  import('@/views/Login/index.vue')
const SubscriptionRecord = () =>
  import('@/views/Login/SubscriptionRecord.vue')
const Page404 = () =>
  import('@/views/404/index.vue')
const routes:Array<RouteRecordRaw> = [
  {
    path: '/:cathchAll(.*)',
    name: '404',
    component: Page404,
    hidden: true,
  },
  {
    path: '/home',
    redirect: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: Home,
        name: 'Home',
        meta: { title: '首页', icon: '' },
      },
      {
        path: '/about',
        component: About,
        name: 'About',
        meta: { title: '关于我们', icon: '' },
      },
      {
        path: '/cdk',
        component: CDK,
        name: 'CDK',
        meta: { title: 'CDK兑换', icon: '' },
      },
      {
        path: '/help',
        component: Help,
        name: 'Help',
        meta: { title: '问题帮助', icon: '' },
      },
      {
        path: '/info',
        component: Info,
        name: 'Info',
        meta: { title: '加速资讯', icon: '' },
      },
      {
        path: '/infocontent',
        component: InfoContent,
        name: 'InfoContent',
        meta: { title: '资讯详情', icon: '' },
      },
      {
        path: '/vip',
        component: VIP,
        name: 'VIP',
        meta: { title: '会员', icon: '' },
      },
      {
        path: '/login',
        component: Login,
        name: 'Login',
        meta: { title: '登录', icon: '' },
      },
      {
        path: '/record',
        component: SubscriptionRecord,
        name: 'Record',
        meta: { title: '订阅记录', icon: '' },
      },
    ],
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})
export default router
