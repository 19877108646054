<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { getOrderList } from '@/api'
import { useStore } from '@/store/index'

const vuex = useStore()

const orderList: any = ref([])
onMounted(() => {
  if (vuex.$state.isLogin) {
    getOrderList({ limit: 9999, page: 1 }).then((res) => {
      if (res.code === 200) {
        orderList.value = res.data.records
      }
    })
  }
})
</script>
<template>
  <div class="srbackground">
    <div class="box">
      <div v-for="item, index in orderList" :key="index" class="item">
        <div class="main">
          <div class="name">
            <img class="img" src="@/assets/images/icon_order_vip_20.png">{{ item.serviceName
            }}
          </div>
          <div class="price">￥{{ item.payAmount }}</div>
        </div>
        <div class="msg">交易时间：{{ item.serviceName }}</div>
        <div class="msg">购买时长：{{ item.serviceDuration }}天</div>
        <div class="msg">有效时间：{{ item.createTime }}到{{ item.refundTime }}</div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.srbackground {
    width: 100%;
    background-color: #1F2322;
    padding: 60px 16px 80px 16px;
    min-height: calc(100vh - 308px);

    .box {
        display: flex;
        color: #666;

        .item {
            padding: 15px 12px 21px 12px;
            border-bottom: 1px solid #D8D8D8;

            .main {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;

                .name {
                    font-weight: bold;
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 25px;

                    .img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }
                }

                .price {
                    font-weight: bold;
                    font-size: 20px;
                    color: #00DAC6;
                    line-height: 28px;
                }
            }

            .msg {
                margin-top: 8px;
                font-size: 13px;
                color: #CCCCCC;
                line-height: 18px;
            }
        }
    }
}
</style>
