<script lang="ts" setup>
import { reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import {
  codeLogin, passwordLogin, managerInfo, cancellation,
} from '@/api'
import { useStore } from '@/store/index'

let router = useRouter()
const vuex = useStore()

const valMap = reactive({
  phone: '',
  select: 'CN +86',
  code: '',
  password: '',
  toCode: true,
  agree: false,
  sendcode: true,
  time: 60,
  btnText: '发送验证码',
  info: {} as any,
})
const login = () => {
  if (!/^1[3456789]\d{9}$/.test(valMap.phone)) {
    ElMessage.error('请输入正确的手机号！')
    return
  }
  if (!valMap.agree) {
    ElMessage.error('请勾选同意协议！')
    return
  }
  if (valMap.toCode) {
    if (!valMap.code) {
      ElMessage.error('请输入验证码！')
      return
    }
    codeLogin({ phoneNum: valMap.phone, code: valMap.code, source: 5 }).then((res) => {
      if (res.code === 200) {
        localStorage.setItem('lsspeedname', res.data.userName)
        localStorage.setItem('lsspeedtoken', res.data.tokenEntity.token)
        vuex.$state.name = res.data.userName
        vuex.$state.isLogin = true
        router.push('/')
        ElMessage.success('登录成功！')
      }
    })
  } else {
    if (!valMap.password) {
      ElMessage.error('请输入密码！')
      return
    }
    passwordLogin({ phoneNum: valMap.phone, password: valMap.password, source: 5 }).then((res) => {
      if (res.code === 200) {
        localStorage.setItem('lsspeedname', res.data.userName)
        localStorage.setItem('lsspeedtoken', res.data.tokenEntity.token)
        vuex.$state.name = res.data.userName
        vuex.$state.isLogin = true
        router.push('/')
        ElMessage.success('登录成功！')
      }
    })
  }
}
const sendCode = () => {
  if (valMap.sendcode) {
    if (!/^1[3456789]\d{9}$/.test(valMap.phone)) {
      ElMessage.error('请输入正确的手机号！')
      return
    }
    axios.get(`https://shandian.tuzhou.net/chx-api2/msg/sp/send/code?mobile=${valMap.phone}`).then((res) => {
      if (res.data.code === 200) {
        ElMessage.success('验证码已发送')
        valMap.sendcode = false
        valMap.time = 60
        valMap.btnText = `${valMap.time}s后重新获取`
        let timer = setInterval(() => {
          valMap.time -= 1
          valMap.btnText = `${valMap.time}s后重新获取`
          if (valMap.time === 0) {
            valMap.sendcode = true
            valMap.btnText = '重新获取'
            clearInterval(timer)
          }
        }, 1000)
      } else {
        ElMessage.error(res.data.msg)
      }
    })
  }
}
const exit = () => {
  localStorage.removeItem('lsspeedtoken')
  localStorage.removeItem('lsspeedname')
  vuex.$state.isLogin = false
  router.push('/')
}
const cancle = () => {
  ElMessageBox.confirm('您好！注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明注销账户说明明注销账户说明注销账户说明注销账户说明注销账户说明', '注销账户说明', {
    confirmButtonText: '同意',
    cancelButtonText: '不同意',
  }).then(() => {
    cancellation().then((res) => {
      if (res.code === 200) {
        ElMessage.success('注销成功')
        exit()
      }
    })
  })
}
const jumpUser = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/user')
}
const jumpPirv = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/privacy')
}
onMounted(() => {
  if (vuex.$state.isLogin) {
    managerInfo().then((res) => {
      if (res.code === 200) {
        valMap.info = res.data
      }
    })
  }
})
</script>
<template>
  <div v-show="!vuex.$state.isLogin" class="background">
    <div class="box">
      <div class="form">
        <div class="title">登录/注册</div>
        <div class="tip">请输入手机号登录，如首次登录，输入手机号填写正确的验证码后将自动注册该手机号为您的登录账号，请确保输入正确的验证码。</div>
        <div class="tip">中国大陆手机如在国外漫游使用，国家码请选择“中国+86”。</div>
        <div class="input">
          <el-input v-model="valMap.phone" class="inputwith left" size="large" placeholder="请输入手机号">
            <template #prepend>
              <el-select v-model="valMap.select" placeholder="请选择国家码">
                <el-option label="CN +86" value="1" />
              </el-select>
            </template>
          </el-input>
          <el-input
            v-if="valMap.toCode" v-model="valMap.code" class="inputwith right" style="margin-top: 24px;"
            size="large" placeholder="请输入验证码">
            <template #append>
              <el-button link type="info" @click="sendCode">{{ valMap.btnText }}</el-button>
            </template>
          </el-input>
          <el-input
            v-else v-model="valMap.password" class="inputwith" style="margin-top: 24px;" type="password"
            size="large" placeholder="请输入密码" show-password />
        </div>
        <div class="btn">
          <!-- <el-button link size="small" color="#00DAC6" @click="valMap.toCode=!valMap.toCode">{{ valMap.toCode?'密码':'验证码' }}登录</el-button> -->
          <!-- <el-button link size="small" color="#00DAC6">忘记密码？</el-button> -->
        </div>
        <el-button class="login" type="success" @click="login">登 录</el-button>
        <div class="agree">
          <div class="cycle" :class="{ select: valMap.agree }" @click="valMap.agree = !valMap.agree" />
          <span>登录即同意<span style="color: #00DAC6;cursor: pointer;" @click="jumpUser">《用户协议》</span>与<span
            style="color: #00DAC6;cursor: pointer;" @click="jumpPirv">《隐私协议》</span>，本注册账号适用于其他设备登录</span>
        </div>
      </div>
    </div>
  </div>
  <div v-show="vuex.$state.isLogin" class="background2">
    <div class="box2">
      <div class="head">
        <div v-show="!valMap.info.vipStatus" class="vip not">非会员</div>
        <div v-show="valMap.info.vipStatus" class="vip">VIP</div>
      </div>
      <div class="name">{{ vuex.$state.name }}</div>
      <div class="deadtime">到期时间：{{ valMap.info.vipStatus || "-" }}</div>
      <div class="nowtime">当前账户时长</div>
      <div class="havetime">剩余{{ dayjs().diff('2021-10-18', 'day') }}天</div>
      <div class="paybtn" @click="router.push('/vip')">立即续费</div>
      <div class="btn">
        <el-button link size="small" color="#CCCCCC" @click="router.push('/record')">
          <img class="img" src="@/assets/images/icon_subscription.png">订阅记录
        </el-button>
        <el-button link size="small" color="#CCCCCC" @click="exit">
          <img class="img" src="@/assets/images/icon_quit.png">退出登录
        </el-button>
      </div>
    </div>
    <div class="cancle" @click="cancle">注销账户</div>
  </div>
</template>
<style lang="less" scoped>
.background {
  width: 100%;
  background-color: #1F2322;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 308px);
  padding: 80px 0 52px 0;

  .box {
    display: flex;
    color: #666;

    .img {
      width: 538px;
      height: 600px;
    }

    .form {
      padding: 0 28px;

      .title {
        font-weight: bold;
        font-size: 24px;
        color: #fff;
        line-height: 33px;
        text-align: center;
        margin-bottom: 12px;
      }

      .tip {
        margin-top: 28px;
        font-size: 12px;
        color: #fff;
        line-height: 17px;
      }

      .input {
        margin: 24px 0 16px 0;
      }

      .btn {
        display: flex;
        justify-content: space-between;
        --el-color-black: #00DAC6;
        --el-color-info: rgb(77, 229, 215);
        --el-text-color-primary: rgb(4, 178, 162);
      }

      .login {
        width: 100%;
        height: 44px;
        margin: 40px 0 16px 0;
        background: linear-gradient(90deg, #00DDD5 0%, #00BDCB 100%);
        border-radius: 22px;
        font-size: 16px;
        line-height: 44px;
        border: none;
      }

      .cycle {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        margin-right: 8px;
        border: 2px solid #999;
        border-radius: 10px;

        &.select {
          border: none;
          background: url('@/assets/images/icon_sel_20.png') 0 0/contain no-repeat;
        }
      }

      .agree {
        display: flex;
        align-items: start;
        justify-content: start;
        color: #fff;
      }
    }
  }
}

.background2 {
  width: 100%;
  background-color: #1F2322;
  padding: 48px 16px 44px 16px;
  min-height: calc(100vh - 308px);

  .box2 {
    margin-bottom: 20px;
    padding: 32px 16px 18px 16px;
    background: #282C2B;
    border-radius: 8px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .head {
      width: 48px;
      height: 48px;
      background: url('@/assets/images/head (1).png') 0 0/contain no-repeat;
      position: relative;

      .vip {
        position: absolute;
        left: 10px;
        bottom: -4px;
        width: 28px;
        height: 12px;
        font-size: 9px;
        color: #FFFFFF;
        line-height: 13px;
        text-align: center;
        background: linear-gradient(180deg, #F0986B 0%, #FF5500 100%);
        border-radius: 6px;

        &.not {
          width: 40px;
          left: 4px;
          background: #999999;
        }
      }
    }

    .name {
      margin: 16px 0 4px 0;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
    }

    .deadtime {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
    }

    .nowtime {
      margin: 24px 0 12px 0;
      width: 100%;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 20px;
    }

    .havetime {
      font-size: 20px;
      color: #FFFFFF;
      line-height: 28px;
    }

    .paybtn {
      cursor: pointer;
      margin: 24px 0 20px 0;
      width: 100%;
      height: 40px;
      background: linear-gradient(90deg, #00DDD5 0%, #00BDCB 100%);
      border-radius: 20px;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
    }

    .btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      --el-color-black: #ccc;

      .img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }

  .cancle {
    cursor: pointer;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    text-align: center;
  }
}
</style>
<style lang="less">
.inputwith {
  --el-text-color-regular: #fff;
  --el-color-info: #00DAC6;
}

.el-select__popper {
  --el-bg-color-overlay: #fff;
  --el-text-color-regular: #333;
}

.el-input-group--prepend .el-input-group__prepend .el-select .el-input .el-input__wrapper,
.el-input-group--prepend .el-input-group__prepend .el-select .el-input.is-focus .el-input__wrapper {
  box-shadow: none;
}

.inputwith .el-input-group__prepend,
.inputwith .el-input-group__append {
  padding: 0;
  width: 100px;
  background-color: var(--el-fill-color-blank);
}

.inputwith.left .el-input__wrapper {
  box-shadow: -1px 0 0 0 var(--el-input-border-color) inset, 0 1px 0 0 var(--el-input-border-color) inset, 0 -1px 0 0 var(--el-input-border-color) inset;
}

.inputwith.right .el-input__wrapper {
  box-shadow: 1px 0 0 0 var(--el-input-border-color) inset, 0 1px 0 0 var(--el-input-border-color) inset, 0 -1px 0 0 var(--el-input-border-color) inset;
}
.el-message-box{
  --el-text-color-primary: #333;
  --el-text-color-regular:#333;
  --el-color-primary: #00BDCB;
  --el-color-primary-light-3: #00d1ca;
  --el-color-primary-dark-2: #0096a1;
}
</style>
