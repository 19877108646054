<script lang="ts" setup>
import { ref } from 'vue'
import { getQAList } from '@/api'

const ci: any = ref(0)
const si: any = ref(-1)
const helpList: any = ref([])
const classList: any = ref([])
const nowList: any = ref([])
const changeClass = (index: any) => {
  ci.value = index
  nowList.value = helpList.value[index].questionDetailVoList
}
const changeNow = (index: any) => {
  if (si.value === index) si.value = -1
  else si.value = index
}
getQAList({ source: 10 }).then((res) => {
  if (res.code === 200) {
    helpList.value = res.data
    classList.value = []
    helpList.value.forEach((val: any) => {
      classList.value.push(val.name)
      changeClass(ci.value)
    })
  }
})
</script>
<template>
  <div class="background">
    <div class="text1">常见问题</div>
    <div class="classlist">
      <div
        v-for="item, index in classList" :key="index" class="item" :class="{ select: index == ci }"
        @click="changeClass(index)">
        {{ item }}
      </div>
    </div>
    <div class="helplist">
      <div v-for="item, index in nowList" :key="index" class="whitebox" @click="changeNow(index)">
        <div class="head">
          <div class="question">
            <img class="img1" src="@/assets/images/icon_question.png">{{ item.question }}
          </div>
          <img v-if="index != si" class="img2" src="@/assets/images/icon_arrow_d.png.png">
          <img v-else class="img2" src="@/assets/images/icon_arrow_u.png">
        </div>
        <div v-if="index == si" class="main">{{ item.answer }}</div>
      </div>
    </div>
    <div class="callt"><img class="img" src="@/assets/images/sever.png">在线客服</div>
    <div class="callb">在线时间：AM8:00 — PM22:00</div>
    <div class="callt"><img class="img" src="@/assets/images/mail.png">客服邮箱</div>
    <div class="callb">leidianke@163.com</div>
  </div>
</template>
<style lang="less" scoped>
.background {
  width: 100%;
  background-color: #1F2322;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 16px 40px 16px;

  .text1 {
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
    font-weight: bold;
  }

  .img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }
}

.classlist {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  margin: 24px 0 20px 0;

  &::-webkit-scrollbar {
    height: 0;
  }

  .item {
    flex-shrink: 0;
    cursor: pointer;
    width: 96px;
    margin-right: 12px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 32px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.select {
      background: #CCFCF8;
      color: #00665D;
      font-weight: bold;
    }
  }
}

.helplist {
  width: 100%;
  margin-bottom: 30px;

  .whitebox {
    cursor: pointer;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 10px;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 12px;
      font-size: 14px;
      line-height: 20px;

      .question {
        display: flex;
      }

      .img1 {
        width: 20px;
        height: 20px;
        margin-right: 16px;
      }

      .img2 {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    .main {
      padding: 16px 0;
      margin: 0 16px;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      border-top: 1px solid #ECEEF0;
    }

    .item {
      cursor: pointer;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 22px;

      &.select {
        color: #00DAC6;
      }
    }
  }

  .title {
    margin-bottom: 35px;
    font-size: 24px;
    line-height: 33px;
  }

  .tip {
    font-size: 16px;
    line-height: 22px;
  }
}

.callt {
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  display: flex;
  margin-bottom: 4px;

  .img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.callb {
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  margin-bottom: 15px;
}
</style>
