import { defineStore } from 'pinia'
import { getVersionList } from '@/api'

interface stuList {
  downloadLink?: string;
  plantForm?: number;
  source?: number;
}
export const useStore = defineStore('main', {
  state: () =>
    ({
      title: '生命因何而沉睡',
      num: 10,
      versionList: [] as stuList[],
      isLogin: false,
      name: '',
    }),
  getters: {
    getResult(state) {
      return state.num *= 2
    },
  },
  actions: {
    /** 注意: 不能使用箭头函数来定义actions操作,因为箭头函数会绑定外部的this */
    changeTitle() {
      this.title = '尚未从梦中醒来'
    },
    async getOptionList() {
      const res = await getVersionList({ source: 10 })
      this.versionList = res.data
    },
  },
})
