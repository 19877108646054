<script lang="ts" setup>
// import { ElMessage } from 'element-plus'
import { useStore } from '@/store/index'

const vuex = useStore()
const download = (filename:any, url:any) => {
  let a = document.createElement('a')
  a.style.display = 'none' // 创建一个隐藏的a标签
  a.download = filename
  a.href = url
  document.body.appendChild(a)
  a.click() // 触发a标签的click事件
  document.body.removeChild(a)
}
const winDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 3)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const andDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 1)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const iosDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 2)
  window.open(vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const macDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 4)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
</script>
<template>
  <div class="box1">
    <div class="text1">雷电加速 一键畅连</div>
    <div class="text2">不限流量 低延迟 低抖动</div>
    <div class="tag">
      <div class="box" @click="iosDownload">
        <img class="img" src="@/assets/images/apple_sel.png">
        APP Store
      </div>
      <div class="box" @click="andDownload">
        <img class="img" src="@/assets/images/android_sel.png">
        Android版下载
      </div>
      <div class="box" @click="winDownload">
        <img class="img" src="@/assets/images/win_sel.png">
        Windows下载
      </div>
      <div class="box" @click="macDownload">
        <img class="img" src="@/assets/images/macos_sel.png">
        MacOS
      </div>
    </div>
    <div class="text3">使用方法</div>
    <div class="text4">简单三步，轻松加速</div>
    <div class="tag2">
      <div class="box">
        <img class="img" src="@/assets/images/icon_register.png">
        <div class="text">下载安装</div>
      </div>
      <img class="arrow" src="@/assets/images/icon_use_arrow.png">
      <div class="box">
        <img class="img" src="@/assets/images/icon_login.png">
        <div class="text">注册登录</div>
      </div>
      <img class="arrow" src="@/assets/images/icon_use_arrow.png">
      <div class="box">
        <img class="img" src="@/assets/images/icon_ac.png">
        <div class="text">一键智能加速</div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.box1 {
  width: 100%;
  padding-top: 92px;

  .text1 {
    text-align: center;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 45px;
    font-weight: bold;
  }

  .text2 {
    margin-top: 16px;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
  }

  .tag {
    width: 100%;
    margin: 48px 0 64px 0;
    display: grid;
    text-align: center;
    grid-template-columns: 158px 158px;
    grid-template-rows: 60px 60px;
    grid-gap: 16px 19px;
    justify-content: center;

    .img {
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }

    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #00665D;
      line-height: 20px;
      border-radius: 4px;
      border: 1px solid #fff;
      background: #FFFFFF;
    }
  }

  .text3 {
    width: 100%;
    font-size: 22px;
    color: #FFFFFF;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
  }

  .text4 {
    width: 100%;
    margin: 12px 0 28px 0;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 17px;
    text-align: center;
  }

  .tag2 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 48px;

    .arrow {
      width: 20px;
      height: 20px;
    }

    .box {
      width: 84px;
      height: 84px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .img {
        width: 60px;
        height: 60px;
      }

      .text {
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
</style>
