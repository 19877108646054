<script lang="ts" setup>
import { reactive } from 'vue'
import { getProductList, orderProduct } from '@/api'

const valMap: any = reactive({
  productList: [],
  select: 0,
  payType: 2,
})
const pay = () => {
  orderProduct({
    payType: 2, productId: valMap.productList[valMap.select].id, returnUrl: 'www.baidu.com', source: 10,
  }).then((res) => {
    if (res.code === 200) {
      const div = document.createElement('divform')
      div.innerHTML = res.data.payBody
      document.body.appendChild(div)
      document.forms[0].submit()
    }
  })
}
const getList = () => {
  getProductList({ source: 10, language: 1 }).then((res) => {
    if (res.code === 200) {
      valMap.productList = res.data
    }
  })
}
getList()
const jumpUser = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/user')
}
const jumpPirv = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/privacy')
}
</script>
<template>
  <div class="black">
    <div class="background">
      <div class="center">
        <img class="tip" src="@/assets/images/icon_notice.png">
        <div class="tip">官网充值更加优惠，省+省！！！</div>
      </div>
      <div class="title">会员充值</div>
      <div class="tag1">
        <div
          v-for="item, index in valMap.productList" :key="index" class="box"
          :class="{ select: index == valMap.select }" @click="valMap.select = index;">
          <div class="top">{{ item.name }}</div>
          <div class="main">
            <div class="cost">{{ item.saleMonthPrice }}元/月</div>
            <div class="before">{{ item.originalSaleMonthPrice }}元/月</div>
            <div class="total">{{ item.totalSaleContent }}</div>
            <div class="app">APP端售价{{ item.originPrice }}元/{{ item.totalSaleContent.slice(-1) }}</div>
          </div>
        </div>
      </div>
      <div class="title">支付方式</div>
      <div class="tag2">
        <div class="top">请选择支付方式</div>
        <div class="pay">
          <!-- <div class="box" :class="{ select: valMap.payType == 1 }" @click="valMap.payType = 1"><img class="img" src="@/assets/images/icon_wechat_32.png">微信支付</div> -->
          <div class="box" :class="{ select: valMap.payType == 2 }" @click="valMap.payType = 2">
            <span style="display: flex;align-items: center;"><img
              class="img"
              src="@/assets/images/icon_zhifubao_32.png">支付宝支付</span>
          </div>
          <!-- <div class="box" :class="{ select: valMap.payType == 3 }" @click="valMap.payType = 3"><img class="img" src="@/assets/images/icon_paypal_32.png">PayPal支付</div> -->
        </div>
        <div class="price">
          <div style="display: flex;align-items: end; justify-content: space-between;">
            <div>
              <div class="cost">
                {{ valMap.productList[valMap.select]?.salePrice || 0 }}<span
                  style="font-size: 14px;line-height: 33px;">元</span>
              </div>
              <div class="total">
                每月仅需{{ valMap.productList[valMap.select]?.saleMonthPrice || 0 }}元 <span
                  style="font-size: 12px;line-height: 20px;font-weight: normal">已省<span style="color: #FF1E00;">{{
                  valMap.productList[valMap.select]?.economizePrice || 0 }}</span>元</span>
              </div>
            </div>
            <el-button class="pay" type="success" @click="pay">立即支付</el-button>
          </div>
          <div class="agree">
            支付过程全程加密，保护您的支付安全 开通即代表同意<span style="color: #00DAC6;cursor: pointer;" @click="jumpUser">《用户协议》</span>及<span
              style="color: #00DAC6;cursor: pointer;" @click="jumpPirv">《隐私协议》</span>
          </div>
        </div>
      </div>
      <div class="title">会员尊享权益</div>
      <div class="tag3">
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／global.png">
          <div class="viptitle">全球加速</div>
          <div class="text">优选国际网络专线，畅享全球稳定网络连接</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／acc.png">
          <div class="viptitle">智能加速</div>
          <div class="text">会员享智能路由技术，智能优选最佳线路加速网络</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／line.png">
          <div class="viptitle">多条线路</div>
          <div class="text">会员专享国际多条优质线路</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／communicate.png">
          <div class="viptitle">通讯加密</div>
          <div class="text">自研加密技术，保护用户隐私，安全上网</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／flow.png">
          <div class="viptitle">无限流量</div>
          <div class="text">连接全程不限制加速流量，随用随有</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／service.png">
          <div class="viptitle">客服优先服务</div>
          <div class="text">客服专员优先会员一对一解答问题，服务更贴心</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.black {
  width: 100%;
  background-color: #1f2322;

  .background {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 16px 40px 16px;
    margin: auto;

    .center {
      margin: 16px 0;
      display: flex;
      align-items: center;

      img.tip {
        margin: 0 12px 0 30px;
        width: 20px;
        height: 20px;
      }

      div.tip {
        font-size: 16px;
        color: #FF9900;
        line-height: 22px;
      }
    }

    .title {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      margin: 24px 0 16px 0;
    }

    .tag1 {
      display: flex;
      width: 100%;
      overflow-x: auto;

      .box {
        cursor: pointer;
        flex-shrink: 0;
        width: 144px;
        height: 174px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-right: 10px;

        .top {
          background: #93E3DC;
          border-radius: 8px 8px 0px 0px;
          padding-top: 7px;
          display: flex;
          height: 36px;
          justify-content: center;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          color: #333;
        }

        .main {
          display: flex;
          flex-direction: column;
          align-items: center;

          .cost {
            margin-top: 8px;
            font-size: 24px;
            color: #00665D;
            line-height: 33px;
            font-weight: bold;
          }

          .before {
            margin-top: 4px;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
            text-decoration-line: line-through;
          }

          .total {
            margin-top: 8px;
            background: rgba(216, 216, 216, 0);
            border-radius: 18px;
            border: 1px solid #CCCCCC;
            width: 112px;
            height: 26px;
            font-size: 14px;
            color: #333333;
            line-height: 26px;
            text-align: center;
          }

          .app {
            margin-top: 10px;
            font-size: 12px;
            color: #999999;
            line-height: 17px;
          }
        }

        &.select {
          background: #D6FBF8;
          border: 2px solid #00DAC6;

          .top {
            background: #00DAC6;
            border-radius: 4px 4px 0px 0px;
            padding-top: 5px;
            height: 34px;
          }
        }
      }
    }

    .tag2 {
      width: 100%;
      background: #F6F8FA;
      border-radius: 8px;
      padding: 16px 12px;
      color: #333333;

      .top {
        font-size: 16px;
        line-height: 22px;
      }

      .pay {
        display: flex;
        align-items: center;
        margin: 20px 0 2px 0;

        .box {
          cursor: pointer;
          width: 100%;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: rgba(246, 248, 250, 0);
          border-radius: 8px;
          border: 2px solid #DFE5E5;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          padding-left: 16px;

          .img {
            width: 32px;
            height: 32px;
            margin-right: 12px;
          }

          &.select {
            background: #FFFFFF;
          }
        }
      }

      .price {
        margin-top: 16px;

        .pay {
          width: 120px;
          height: 32px;
          background: linear-gradient(90deg, #00DDD5 0%, #00BDCB 100%);
          border-radius: 22px;
          font-size: 16px;
          line-height: 44px;
          border: none;
        }

        .top {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 40px;
          font-weight: bold;
        }

        .cost {
          font-size: 24px;
          color: #00665D;
          line-height: 33px;
          font-weight: bold;
        }

        .total {
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
        }

        .agree {
          width: 237px;
          margin: 20px auto 0 auto;
          font-size: 12px;
          color: #808080;
          line-height: 17px;
          text-align: center;
        }
      }

      .paytip {
        margin-top: 36px;
        font-size: 16px;
        color: #808080;
        line-height: 22px;
      }
    }

    .tag3 {
      display: grid;
      grid-template-columns: repeat(2, 49%);
      grid-template-rows: 136px 136px;
      grid-row-gap: 12px;
      grid-column-gap: 2%;
      justify-content: space-between;
      width: 100%;
      color: #333333;

      .box {
        // width: 166px;
        height: 136px;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img {
          width: 44px;
          height: 44px;
          margin: 12px 0 8px 0;
        }

        .viptitle {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
        }

        .text {
          width: 132px;
          font-size: 12px;
          line-height: 17px;
          margin-top: 4px;
          text-align: center;
        }
      }
    }
  }
}
</style>
